@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-MediumItalic.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-MediumItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-BoldItalic.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-Light.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Neue';
	src:
		url('/fonts/HelveticaNeue-LightItalic.woff2') format('woff2'),
		url('/fonts/HelveticaNeue-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

/* Fallback fonts generated by fontpie-from-css  */

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: normal;
	font-weight: bold;
	src: local('Arial Bold');
	ascent-override: 97.45%;
	descent-override: 21.79%;
	line-gap-override: 5.7%;
	size-adjust: 100.05%;
}

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: italic;
	font-weight: normal;
	src: local('Arial Italic');
	ascent-override: 95.56%;
	descent-override: 21.27%;
	line-gap-override: 2.8%;
	size-adjust: 100.14%;
}

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: normal;
	font-weight: 300;
	src: local('Arial');
	ascent-override: 92.95%;
	descent-override: 20.69%;
	line-gap-override: 2.76%;
	size-adjust: 104.9%;
}

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: italic;
	font-weight: bold;
	src: local('Arial Bold Italic');
	ascent-override: 97.6%;
	descent-override: 21.72%;
	line-gap-override: 2.9%;
	size-adjust: 99.9%;
}

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: normal;
	font-weight: 300;
	src: local('Arial');
	ascent-override: 99.55%;
	descent-override: 21.93%;
	line-gap-override: 2.99%;
	size-adjust: 97.14%;
}

@font-face {
	font-family: 'Helvetica Neue Fallback';
	font-style: italic;
	font-weight: bold;
	src: local('Arial Bold Italic');
	ascent-override: 106.44%;
	descent-override: 23.84%;
	line-gap-override: 3.13%;
	size-adjust: 89.35%;
}
